import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import processGif from '../../../images/careermentor/process-animation.gif'

const CareerMentorCompOne = () => {

  const data = useStaticQuery(graphql`
    query {
      imageOne: file(relativePath: { eq: "careermentor/cm-frontpage-hero-mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageTwo: file(relativePath: { eq: "careermentor/cm-video-module-mockup.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageThree: file(relativePath: { eq: "careermentor/cm-blog-post-mockup.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageFour: file(relativePath: { eq: "careermentor/cm-perks-mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageFive: file(relativePath: { eq: "careermentor/cm-team-mockup.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="ProjectSection ProjectSection--CmOne outer-padding-y outer-padding-x bg-white">
      <div className="fadein">
        <Img fluid={data.imageOne.childImageSharp.fluid} />
      </div>
      <div className="fadein">
        <figure>
          <img src={processGif} alt="Process animation"/>
        </figure>
        <figure>
          <Img fluid={data.imageTwo.childImageSharp.fluid} />
        </figure>
      </div>
      <div className="fadein">
        <figure>
          <Img fluid={data.imageThree.childImageSharp.fluid} />
        </figure>
        <figure>
          <Img fluid={data.imageFour.childImageSharp.fluid} />
        </figure>
      </div>
      <div className="fadein">
        <figure>
          <Img fluid={data.imageFive.childImageSharp.fluid} />
        </figure>
      </div>
    </section>
  )
}

export default CareerMentorCompOne