import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import matchingAnimation from '../../../images/careermentor/searching-animation.gif'
import navigationAnimation from '../../../images/careermentor/app-navigation-animation.gif'

const CareerMentorCompTwo = () => {

  const data = useStaticQuery(graphql`
    query {
      imageOne: file(relativePath: { eq: "careermentor/cm-app-match-mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageTwo: file(relativePath: { eq: "careermentor/cm-app-profiles-mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="ProjectSection ProjectSection--CmTwo outer-padding-y outer-padding-x bg-white">
      <div className="grid-x grid-padding-x">
        <div className="cell medium-4">
          <div className="fadein">
            <figure>
              <img src={matchingAnimation} alt="Matching animation"/>
            </figure>
            <figure>
              <Img fluid={data.imageOne.childImageSharp.fluid} />
            </figure>
          </div>
        </div>
        <div className="cell medium-4">
          <div className="fadein">
            <figure>
              <Img fluid={data.imageTwo.childImageSharp.fluid} />
            </figure>
          </div>
        </div>
        <div className="cell medium-4">
          <div className="fadein">
            <figure>
              <img src={navigationAnimation} alt="Navigation animation"/>
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CareerMentorCompTwo