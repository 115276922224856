import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../../components/seo"
import ProjectsData from "../../data/projects"
import InnerLayout from "../../components/InnerLayout"
import InnerWrapper from "../../components/InnerWrapper"
import ProjectHero from "../../components/project/Hero"
import ProjectWrapper from "../../components/project/Wrapper"
import ProjectIntro from "../../components/project/Intro"
import ProjectSection from "../../components/project/Section"
import CareerMentorCompOne from "../../components/project/careermentor/CareerMentorCompOne"
import CareerMentorCompTwo from "../../components/project/careermentor/CareerMentorCompTwo"
import ProjectNext from "../../components/project/Next"

const CareerMentor = (pageContext) => {
  const projectsData = ProjectsData();
  const projectId = 1;
  const projectData = projectsData.find(data => data.id === projectId);

  const images = useStaticQuery(graphql`
    query {
      cmMockupOne: file(relativePath: { eq: "careermentor/cm-home-mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cmMockupAppHome: file(relativePath: { eq: "careermentor/cm-app-home-mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cmMockupAppChat: file(relativePath: { eq: "careermentor/cm-app-chat-mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cmImageOne: file(relativePath: { eq: "careermentor/cm-image-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const contentSectionOne = (
    <div>
      <h3>A professional but human website</h3>
      <p>Visitors first meet a website articulating CareerMentors concept and mission. Here people can get familiar with the idea of mentorship in a career context. They can listen to both a mentor and a mentee telling their stories and benefits of learning and sharing. The website is also a platform for sharing resources and blog posts that inspire and provide tools two facilitate a successful mentorship. It provides a gateway to signing up for the actual platform.</p>
      <ul className="list-unstyled"><li>- Helpful video testimonials</li><li>- Engaging SVG explainer animations</li><li>- Blogging platform</li><li>- Clear call to actions</li><li>- Professional imagery</li><li>- Welcoming colors</li><li>- Easy navigation</li><li>- Dynamic identity</li></ul>
    </div>
  );

  const contentSectionThree = (
    <div>
      <h3>Friendly and engaging web application</h3>
      <p>To access the platform users follow a guiding multi step sign up flow to ensure a professional profile. When logged in you're immediately presented the match making module. It enables users to match with a potential mentor or mentee based on their industry, interests and location. Once matched by the algorithm a mentee can request to connect with a mentor with a short written application. If a request is accepted the conversation continue in the chat module.</p>
      <ul className="list-unstyled"><li>- Easy authentication</li><li>- Sign up flow</li><li>- User profiles</li><li>- Match maker algorithm</li><li>- Real time chat</li><li>- Email notifications</li><li>- Notification system</li><li>- API integrations</li><li>- Achievement system</li></ul>
    </div>
  );

  return (
    <InnerLayout noTopPadding>
      <SEO title="CareerMentor project" bodyClass="ProjectPage" />
      <ProjectHero 
        title={projectData.title}
        subtitle={projectData.excerpt}
        color={projectData.color}
        image={projectData.image}
      />
      <InnerWrapper>
        <ProjectWrapper>
          <ProjectIntro
            client={projectData.client}
            website={projectData.website}
            agency={projectData.agency}
            year={projectData.year}
            category={projectData.category}
            role={projectData.role}
            technologies={projectData.technologies}
            problem="During your career you may be lucky to run into persons creating a profound impact on your professional life. I know this certainly have been the case for me, but not everybody may have been lucky enough to find the perfect mentor who are willing to share their learnings freely. Fortunately it happens that a lot of experienced people cross various fields are willing to share their knowledge with people they trust and see a potential in. Everybody has something to learn, and everybody have learnings to share. Now the hard part is to facilitate the connection and making sure the right persons cross paths."
            solution="CareerMentor is a Danish platform that seeks to solve just that. It's core value proposition is matching potential mentors and mentees in their professional life. Mentors get to share their knowledge, build network and give back on a platform that prevets potential mentees reducing the risk of misusing their time and trust. Mentees gets valuable sparring and advice from a experienced person that are not their boss or close colleague."
          />
          <ProjectSection
            type="columns"
            bgcolor="lightgray"
            content={contentSectionOne}
            images={[images.cmMockupOne.childImageSharp.fluid]}
          />
          <CareerMentorCompOne />
          <ProjectSection
            type="columns"
            bgcolor="lightgray"
            reversed={true}
            content={contentSectionThree}
            images={[images.cmMockupAppHome.childImageSharp.fluid, images.cmMockupAppChat.childImageSharp.fluid]}
          />
          <CareerMentorCompTwo />
          <ProjectSection
            bgcolor="white"
            images={[images.cmImageOne.childImageSharp.fluid]}
            fullwidth
          />
          <ProjectNext project={1} />
        </ProjectWrapper>
      </InnerWrapper>
    </InnerLayout>
  )
}

export default CareerMentor
